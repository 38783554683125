.swiper-controll-wrapper {
  height: 150px;
  width: 100%;
  position: relative;
}

.swiper-container {
  .swiper-pagination {
    @include responsive(phone) {
      display: block;
    }
    display: none;
    font-size: 2.5rem;
    width: fit-content;
    right: 0;
    left: 0;
    margin: auto;
    color: #000000;
    position: absolute;
    opacity: 0.7;
  }
}

.swiper-button-next, .swiper-button-prev {
    width: 65px;
    height: 65px;
}


.swiper-button-next:after, .swiper-button-prev:after {
  content: none;
}

.swiper-button-next {
  background: get-icon('arrow-right', #fff) no-repeat, $color-primary;
  background-size: 50%;
  background-position: center;
}

.swiper-button-prev {
  background: get-icon('arrow-left', #fff) no-repeat, $color-primary;
  background-size: 50%;
  background-position: center;
}

.customers-swiper {
  overflow: visible;
  position: relative;
  .swiper-wrapper {
    align-items: center;
    max-width: 800px;
  }
  .swiper-button-next {
    height: 35px;
    width: 35px;
  }
  .swiper-button-next {
    @include responsive(landscape) {
      display: block;
      background: get-icon('double-arrow-right', grey) no-repeat;
      width: 35px;
      height: 35px;
      background-size: 100%;
    }
    display: none;
  }
}



.swiper-pagination {
  left: 0;
  right: 0;
  margin: auto;
  color :$color-primary;
}

.testimonials-swiper {
  h3 {
    padding: 20px 20px 0px 20px;
  }
  p {
    padding: 0 20px 20px 20px;
  }
}


.slider-element {
  position: relative;
  &:hover {
    &__overlay {
      opacity: 1;
    }
  }
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(211, 211, 211, 0.8);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  &__textcontent {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    h1, h2, h3 {
      text-transform: uppercase;
      color: #fff;
      font-size: 2rem;
    }
  }
}