//Eventlist

.event {
  &__list {
    align-items: center;
    padding-bottom: 25px;
  }
  &__column {
    &.odd:first-child {
      order: 12;
      @include responsive(desktop) {
        order: 12
      }
    }
    &.even:first-child {
      order: 12;
      @include responsive(desktop) {
        order: 1
      }

    }
    &.even:last-child {
      order: 1;
      @include responsive(desktop) {
        order: 1
      }

    }
    img {
      margin-left:0;
      margin-right: 0;
    }
  }
}

.mod_eventlist > h2 {
  display: none;
}

//End Eventlist