


//=======================> Startseite Header
#header {
	position: relative;
	overflow: hidden;
	// margin-bottom: $gap-default;
}


.header__start {
	position: relative;
	min-height: 570px;
	overflow: hidden;
	.header__inner {
		position:absolute;
		bottom: 5%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		z-index: 1;
		.header__headline {
			display: flex;
			flex-direction: column;
			h1{
				color: #fff;
				font-size: 2.2rem;
				display: block;
				position: relative;
				margin:0;
				overflow: hidden;
				visibility: visible;
				font-size: 1.8rem;
				@include responsive (phone) {
					font-size: 2rem;
				}
				@include responsive (tablet) {
					font-size: 2rem;
				}
				@include responsive (landscape) {
					font-size: 2.2rem;
				}
				@include responsive (desktop) {
					font-size: 2.5rem;
				}
				@include responsive (extralarge) {
					font-size: 3rem;
				}
				span {
					display: block;
				}
			}
		}
	}
	.header__image {
		@include responsive(landscape) {
			position: absolute;
			width: 48vw;
			bottom: -100px;
			top: 135px;
			right: 0;
			height: 100%;
			display: block;
		}
		display: none;
		img {
			display: flex;
			width: 100%;
			object-fit: cover;
			height: 750px;
		}
	}
	.header__pattern-overlay {
		display: flex;
		position: absolute;
		bottom: 0;
		opacity: 1;
	}
}

.mod_pageimage {
	height: 100vh;
	width: 100%;
	img {
		object-fit: cover;
		height: 100vh;
		width: 100%;
	}
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: black;
		top: 0;
		left: 0;
		opacity: .2;
		z-index: 1;

	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("/files/images/src/reibenerhof_pattern.svg");
		background-position: left bottom;
		background-size: 40%;
		background-repeat: no-repeat;
		top: 0;
		left: 0;
		opacity: .2;
		z-index: 2;
	}

	.slider-control {
		position: absolute;
		left: 0;
		bottom: 40px;
		width: 100%;
		z-index: 2;

		> a {
			display: none;
			color: transparent;
			font-size: 0;
		}

		> .slider-menu {
			position: absolute;
			left: 50%;
			bottom: 0;
			font-size: 0;

			b {
				position: relative;
				font-size: 0;
				display: inline-block;
				width: 30px;
				height: 30px;
				margin: 0 6px;
				border: 4px solid #ffffff;
				border-radius: 50%;
				color: transparent;

				&:before {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background-color: #fff;
				}

				&:not(:hover):not(.active):before {
					background-color: transparent;
				}

				&.active:before {
					opacity: .3;
				}
			}
		}
	}
}

.header__sub, .header__start {
	position: absolute;
	bottom: 10%;
	z-index: 3;
	right: 0;
	width: 100%;
	height: 200px;
	h1 {
		color: #fff;
	}
	img {
		width: 100%;
		display: flex;
	}
}

.main-navigation-wrapper {
	.logo {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		svg path, rect{
			//fill: #fff;
		}
	}
}

.no-header-image {
	.header__sub {
		min-height: 500px;
	}
	.header__headline {
		padding-bottom: 40px;
	}
	.main-navigation-wrapper {
		background: none;
	}
}

//=====================================> End

.breadcrumb {
	text-transform: uppercase;
	padding-top: 15px;
	ul {
		display: flex;
	}
	span {
		padding: 0 10px;
	}
	li {
		list-style: none;
	}
}
