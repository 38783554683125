.form {
	display: flex;
	flex-direction: column;
	label {display: flex; font-size: 0.85rem; margin-top: 5px}
	
	input:not([type='checkbox']):not([type="file"]), textarea, select {
		width: 100%;
		margin-top: 10px;
		padding: 20px 15px;
		background: #fff;
		border: solid 1px lightgrey;
		&:focus {
			border-bottom: solid 1px $color-primary;
			outline: none
		}
	}
	.widget {
		margin-top: 10px;
		position: relative;
	}
	.widget-select {
		display: flex;
		select {
			padding: 20px;
			width: 100%;
		}
	}
}

//Floating Labels
.form__floating {
	input:not([type="checkbox"]):not([type="hidden"]) + label {
		position: absolute;
		bottom: 0;
		transform: translate(15px, 0);
		transition: transform 0.2s;
	}
	input:not([type=checkbox]).active + label {
		transform: translate(0, -30px) scale(0.9);
		transition: transform 0.2s;
		color: grey
	}
}

option{
	box-shadow: 0 0 10px 100px #FED20F inset;
	transition: all .2s ease-in-out;
}


input, textarea, select {
	border: solid 0.5px #a9a9a9;
	background: #fff;
	padding: 10px;
}

//Checkbox

.checkbox_container input[type=checkbox] {
	position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
}

.checkbox_container label {
	position: relative;
	padding-left: 30px;
	font-size: 0.7rem;
}

.checkbox_container span {
	position: absolute;
	display: flex;
	align-items: center;
    justify-content: center;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: solid 1px $color-primary; 
}


.checkbox_container i {
	display: none
}

.checkbox_container input:checked ~ span i {
	display: inline-block;
	font-size: 1.1rem;
}


//Booking form teaser
.booking-form-teaser {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-image: url("/files/images/icons/lindenhof_pattern.svg");
	background-color: $color-primary;
	background-size: contain;
	margin-bottom: 0;
	z-index: 99;
	display: none;
	@include responsive(landscape) {
		display: block;
	}
	.formbody {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 1280px;
		width: 100%;
		background: $color-secondary;
		margin: auto;
		padding: 10px 0;
	}

	.widget {
		position: relative;
		font-size: 0.85rem;
		&:not(:last-child) {
			margin-right: 10px;
		}
		label {
			position: absolute;
			display: flex;
			justify-content: space-between;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			padding: 10px;
			color: #5f5f5f;
			div.active {
				visibility: hidden;
			}
		}
	}

	input, select, textarea {
		margin-top: 0;
		background: #fff;
		border: none;
		color: #5f5f5f;
	}
}


.booking-form {
	input, select, textarea {
		padding: 20px;
	}
}

.enfore-action-bar {
	bottom: 80px !important;
	background: url('/files/images/icons/tisch-reservieren.png');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	width: 90px;
	height: 90px;
	justify-content: center;
	padding: 5px;
	.enfore-action {
		width: 60px;
		height: 60px !important;
		border-radius: 50% !important;
	}
}