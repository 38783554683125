/*========================Footer======================*/
#footer {
	color: #fff;
	font-size: 0.9rem;
	background-image: url("/files/images/src/reibenerhof_pattern.svg");
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
	background-color: $color-secondary;
	padding-top: 20px;
	.footer__wrapper {
		padding: 50px 0px 20px 0px;
	}
	.footer__links {
		padding-bottom: 40px;
		border-bottom: solid 1px $color-secondary;
		ul {
			width: 70%;
			margin: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			color: $color-secondary;
		}
		h4 {font-size: 1.2rem; color: $color-tertiary;}
		li {padding: 0 5px}
	}
	.footer__social {
		display: flex;
		font-size: 1.5rem;
		a:not(:first-child) {
			padding-left: 15px
		}
		a {
			padding-top: 15px;
		}
	}
	h3 {
		color: #fff;
	}
	h4, h4 a {
		color: #000;
		font-weight: 700;
		text-transform: uppercase
	}
	a {
		color: $color-tertiary;
	}
}


.footer__logo {
	display: block;
	margin: 0 auto 25px auto;
	max-width: 250px;
	svg g {
		fill: #fff;
	}
}




.copyright-wrapper {
	background-color: $color-secondary;
	color: #fff;
	.copyright {
		font-size: 0.7rem;
	}
	.copyright__navigation {
		display: flex;
		justify-content: flex-start;
		margin-top: 25px;
		@include responsive(phone) {
			justify-content: flex-end;
			margin-top: 0px;
		}
		ul {
			display: flex;
			list-style: none;
			li:first-child {
				margin-right: 10px;
			}
		}
	}
	a {color: #fff}
}
