//Newsletter
.newsletter {
  .widget-submit {
    border: solid 2px #fff;
  }
  .widget-explanation {
    font-size: 0.8rem;
  }
  &__textcontent {
    font-size: 1.5rem;
  }
  .newsletter__privacy {
    align-items: start;
  }
  .newsletter__input {
    width: 85%;
    display: flex;
    input {
      width: 100%;
    }
  }
}