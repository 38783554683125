html {
	font-size: 17px;
}


body {
	background: #fff;
}


table,tr,td {border: none;}




h1 {
	@include responsive(phone) {
		font-size: 2.8rem;
	}
	font-family: $font-stack-h1;
	font-size: 2.2rem;
	margin-top: 0;
}

h2 {
	@include responsive (phone) {
		font-size: 2.2rem
	}
	font-family: $font-headline;
	margin-bottom: 10px;
	font-size: 1.5rem;
	color: $color-dark;
	line-height: 1.1;
}

.subheadline {
	color: $color-secondary;
}

h3 {
	font-size: 1.8rem;
	font-family: $font-headline;
	color: $color-dark;
	font-weight: $bold; 
}

h1, h2, h3 {
	.highlight {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		&:before {
			content: "";
			background: $color-secondary;
			height: 2px;
			width: 50px;
			display: flex;
			align-items: center;
			margin-right: 10px;
		}
		&:after {
			content: "";
			background: $color-secondary;
			height: 2px;
			width: 50px;
			display: flex;
			align-items: center;
			margin-left: 10px;
		}
	}
}



.main {
	ul  {
		list-style-position: inside;
		list-style-type: disc;
		li {
			margin-bottom: 10px;
		}
	}
}

a {
	color: $color-primary;
	&:hover {
	text-decoration: none;
	//color: darken($color-primary, 70%)
	}
}

.image {
	&__rounded {
		figure {
			display: flex;
			max-width: 160px;
			max-height: 160px;
			min-width: 100px;
			min-height: 100px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 5%;
		}
	}
}


figure {
	margin: 0;
	figcaption {
		font-size: 0.9rem;
		color: #9a9a9a;
	}
}

strong {
	font-weight:600;
}


.bg-has-pattern {
	background-image: url("/files/images/src/reibenerhof_pattern.svg");
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;
}

//Listing
.listing--vertical {
	ul {
		li {
			margin-bottom: 10px;
			&:before {
				content: "";
				background: get-icon('rectangle', $color-secondary) no-repeat;
				height: 15px;
				width: 15px;
				background-size: contain;
				margin-right: 10px;
				display: inline-block;
				margin-right: 10px;
			}
		}
	}
}

.quote {
	min-height: 650px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	blockquote {
		font-style: italic;
		position: relative;
		color: $color-secondary;
		text-align: center;
		&:before {
			content: "";
			background: get-icon('quote', $color-primary) no-repeat;
			display: inline-block;
			height: 60px;
			width: 60px;
			background-size: 100%;
		}
		p {
			@include responsive(phone) {
				font-size: 2.1rem;
			}
			max-width: 100%;
			font-size: 1.7rem;
			line-height: 1.5;
			margin: auto;
		}
		cite {
			margin-top: 20px;
			color: $color-dark;
			display: flex;
			align-items: center;
			&:before {
				content: "";
				background: get-icon('quote', $color-primary) no-repeat;
				display: inline-block;
				height: 20px;
				width: 20px;
			}
		}
	}
	figure figcaption{
		text-align: center;
		color: $color-primary;
	}
}

.container {
	max-width: $container-width;
	overflow: visible;
}

.shadowed {
	box-shadow: $shadowed;
	overflow: visible;
	display: inline-block
}


.highlight {
	h2 {
		margin-bottom: 25px;
		justify-content: center;
		&::after {
			margin-top: 10px;
			content:"";
			width: 60px;
			height: 5px;
			display: block;
			background-color: $color-primary;
		}
	}
}

//gaps
.mt-default {
	@include responsive (landscape) {
		margin-top: $gap-default;
	}
	margin-top: $gap-default-mobile 
}

.mb-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default
	}
	margin-bottom: $gap-default-mobile
}

.my-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default;
		margin-top: $gap-default
	}
	margin-bottom: $gap-default-mobile;
	margin-top: $gap-default-mobile
}

.py-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default;
		padding-top: $gap-default
	}
	padding-bottom: $gap-default-mobile;
	padding-top: $gap-default-mobile
}

.pt-default {
	@include responsive (landscape) {
		padding-top: $gap-default
	}
	padding-top: $gap-default-mobile
}
.pb-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default
	}
	padding-bottom: $gap-default-mobile
}


/*========================Header======================*/




/*========================Main-Content======================*/


.row {
	&--flex {
		display: flex;
		& > * {
			display:flex; 
		}
	}
}

.shadowed {
    -webkit-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    -moz-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    box-shadow: 8px 5px 20px 1px rgba(0,0,0,0.15);
}

.block {
	&__visible {
		overflow: visible;
	}
}

.box {
	position: relative;
	overflow: visible;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-bottom: solid 10px $color-primary;
	box-shadow: $shadowed;
	margin-bottom: 40px;
	background: #fff;
	font-size: 0.9rem;
	&__bg {
		background-size: contain;
		background-repeat: no-repeat; 
		background-position: top center; 
	}
}


.linkbox {
	@include responsive (desktop) {
		margin: 0;
	}
	@include responsive (phone) {
		min-height: 600px;
	}
	min-height: 280px;
	overflow: hidden;
	position: relative;
	margin: 0 auto 20px auto;
	z-index: 1;
	opacity: 1;
	& > a {
		color: #fff;
		position: absolute;
		left: 0;
		right: 0;
		font-size: 0.9rem;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		&:hover {
			img {
				transform: scale(1.05);
				transition:all .8s ease;
			}
		}
		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(0deg,$color-primary,rgba($color-primary, 0));
			height: 100%;
			display: block;
			width: 100%;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
	h2 {
		color: #fff;
		padding: 40px;
		font-size: 1.5rem;
		position: absolute;
		z-index: 2;
	}
	&__teasertext {
		color: #fff;
		opacity: 0;
		position: absolute;
		bottom: -40px;
		padding: 40px;
	}
	svg {
		opacity: 0;
		position: absolute;
		right: 40px;
		bottom: 10%;
	}
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all .8s ease-in-out;
	}
}





.news {
	&__latest {
		font-size: 0.9rem;
		&__inner {
			position: relative;
		}
		img {
			width: 100%;
			display: flex;
		}
		.ce_text {
			display: none;
		}
		h3 {
			font-size: 1.6rem;
			margin-top: 0;
		}
	}
	&__meta {
		display: flex;
		justify-content: space-between;
		display: none;
	}
	&__category {
		font-size: 13px;
		line-height: 18px;
		padding: 3px 8px; 
		color: $grey;
		border-radius: 2px;
		border: solid 1px #a3a3a3; 
	} 
	&__quotes {

		a {
			color: #fff;
		}
		h3 {
			margin: 0;
			font-size: 2rem;
			color: #fff;
		}
	}
	&__col {
		margin-bottom: 180px;
	}
	&__customer-title {
		font-weight: $bold;
		font-size: 1.45rem;
	}
}

.mod_newsreader {
	.ce_text, h1, h2, h3 {
	max-width: 900px !important;
	margin: auto;
	margin-top: 20px;
}
}

.news__latest.even {
	figure {
		@include responsive(tablet) {
			margin-top: 60px;
		}
		margin-bottom: 20px;
		width: 100%;
	}
	.news__latest__overlay {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: #fff;
		padding: 20px;
	}
}


.quote {
	&__wrapper {
		position: relative;
		&:after {
			content: "";
			background: $grey;
			position: absolute;
			width: 55%;
			height: 100%;
			display: block;
			bottom: 0;
			right: 0;
			z-index: -1;
		}
	}
}



/*
*******************
** Subpages Styling
*******************
*/


.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	transition-timing-function:linear!important; //Wichtig für kontinuierliches scrolling
}



////=========================Sections

.section {
	&__360grad {
		height: 560px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		color: #fff;
		font-size: 1.5rem;
	}
	&__contact {
		.textbox {
			@include responsive(landscape) {
				padding: 5vw;
			}
			@include responsive(extralarge) {
				padding: 5vw 15vw 5vw 5vw;
			}
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.imagebox img{
			height: 500px;
			width: 100%;
			object-fit: cover;
			@include responsive(landscape) {
				height: 760px;
			}
		}
	}

	&__service {

	}
	&__featured {
		position: relative;
	}
	&__benefits {
		.benefit {
			margin-bottom: 25px;
			img {
				margin: unset;
			}
		}
	}
}


.icon-box {
	margin-bottom: 40px;
	figure {
		display: flex;
		justify-content: center;
	}
}

.custom-heading {
	@include responsive (phone) {
		left: 0;
		width: 100%;
		transform: translateX(0);
	}
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;


	left: 50%;
	transform: translateX(-50%);
	width: 180%;
}



.service {
	&__wrapper {
		font-size: 0.85rem;
		svg {
			height: 65px;
			width: 100%;
			fill: $color-dark;
		}
		.ce_text {
			margin-bottom: 25px;
		}
	}
	&__list {
		background-color: $color-primary;
		color: #fff;
		position: relative;
		padding-top: 120px;
		@include responsive(landscape) {
			padding-top: 120px;
		}
		li {
			padding: 10px 0 25px 0;
			margin-bottom: 25px;
			font-size: 1.2rem;
			border-bottom: solid 2px #fff;
		}
		h2 {
			margin-bottom: 35px;
		}
		&:before {
			content: "";
			background: #f3f3f3;
			height: 80px;
			width: 80px;
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			transform: rotate(45deg);
			top: -40px;
		}
	}
	&__navigation {
		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			font-size: 1.2rem;
			@include responsive(phone) {
				font-size: 1.7rem;
			}
			@include responsive(landscape) {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
			}
			li:first-child {
				margin-bottom: 35px;
				@include responsive(landscape) {
					margin-bottom: 0px;
				}
			}
			span {
				&:first-child {
					margin-right: 10px;
				}
				&:last-child {
					margin-left: 10px;
				}
			}
		}
	}
}


.news {
	&__jobs, &__latest {
		&__box {
			background: #fff;
			padding: 25px;
			font-size: $font-size-small;
			border: solid 2px $color-primary;
			margin-bottom: 40px;
			.image_container {
				margin-bottom: 20px;
			}
			time {
				text-transform: uppercase;
				position: absolute;
				background: $color-primary;
				color: #fff;
				padding: 15px;
				top: 0;
				left: 0;
				text-align: center;
				width: 100px;
				span {
					display: block;
				}
			}
			.month {
				font-size: 1.2rem;
				margin-bottom: 10px;
				&:after {
					position: absolute;
					content: "";
					background: #fff;
					width: 80%;
					right: 0;
					left: 0;
					height: 2px;
					margin: auto;
					display: block;
				}
			}
			.day {
				margin-top: 10px;
				font-size: 1.2rem;
				font-weight: bold;
			}
			.year {
				font-size: 0.8rem;
				font-weight: bold;
			}
			.image_with_date {
				position: relative;
			}
		}
	}
}


//Breakout | Text / Image
.breakout-container {
	@include responsive(landscape) {
		flex-direction: row;
	}
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&__image-left {
		@include responsive(landscape) {
			width: 50%;
			padding: 0;
			order: 1
		}
		display: flex;
		align-self: stretch;
		padding: 0 15px;
		order: 2;
		figure {
			width: 100%;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	&__image-right {
		@include responsive(landscape) {
			width: 50%;
			padding: 0;
		}
		display: flex;
		align-self: stretch;
		padding: 0 15px;
		figure {
			width: 100%;
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	&__text-left {
		@include responsive(landscape) {
			padding-left: calc(50vw - 800px);
			padding-right: 100px;
			width: 50%;
		}
		padding: 25px 15px;
	}
	&__text-right {
		@include responsive(landscape) {
			padding-right: calc(50vw - 800px);
			padding-left: 100px;
			width: 50%;
			order: 2
		}
		padding: 25px 15px;
		order: 1
	}
}

// End Breakout | Text / Image



.ce_gallery {
	.image_container {
		overflow: hidden;
		&:hover img {
			transform: scale(1.2);
		}
	}
	.row {
		justify-content: center;
		align-items: center;
	}
	img {
		transition: transform ease-in-out .3s;
	}
}



//======OpenStreetMap//
.c4g-popup-wrapper {
	border-radius: 0 !important;
	padding: 25px !important;
}


//======EUF Overlay//
.euf_overlay__content {
	padding: 0px;
	display: flex;
	max-width: 470px;
}

.euf_overlay__close {
	color: #fff;
}


.reveal {
	visibility: hidden;
	overflow: hidden;
}


.phone-number {
	font-size: 1.5rem;
}



.rooms__teaser {
	&:hover {
		p {opacity: 1;height: auto}
		.textcontent {color: #fff;}
		&:after {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: $color-primary;
			z-index: 1;
			transition: background-color .3s ease;
		}

	}
	&:after {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: none;
		z-index: 1;
		transition: background-color .3s ease;
	}
}


.rooms__teaser {
	margin-bottom: 15px;
	position: relative;
	p {
		opacity: 0;
		height: 0;
	}
	h3 {
		color: #fff;
		font-size: 2rem;
	}
	.textcontent {
		padding: 25px;
		top: 0;
		text-align: center;
		z-index: 9;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		transform: translateY(-50%);
	}
	figure {
		position: relative;
	}
	figcaption {
		display: inline-flex;
		background: $color-primary;
		position: absolute;
		bottom: 5%;
		left: 5%;
		border-radius: 6px;
		padding: 15px;
		color: #fff;
		font-size: 0.85rem;
	}
	.btn {
		//position: absolute;
		bottom: 25px;
	}

}

.services {
	&__card {
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background: #fff;
		@include responsive(tablet) {
			align-items: stretch;
			flex-direction: row;
		}
		.textcontent {
			position: relative;
			padding: 100px 40px 40px 40px;
			font-size: 0.85rem;
			width: 100%;
			@include responsive(tablet) {
				width: 40%;
			}
		}
		figure {
			width: 100%;
			height: 100%;
			@include responsive(tablet) {
				width: 60%;
			}
		}
		img {
			width: 100%;
			height: 480px;
			object-fit: cover;
		}
		&__details {
			position: absolute;
			top: 0;
			right: 0;
			background: #d3c1a1;
			font-size: 0.85rem;
			padding: 10px;
			display: flex;
			justify-content: flex-end;
			span {
				display: flex;
				align-items: center;
			}
			span:not(:last-child) {
				padding-right: 10px;
			}
			svg {
				margin-right: 5px;
				width: 20px;
			}
		}
	}
	&__container {
		position: relative;
	}
	&__price {

	}

}

.image__column {
	position: relative;
	.image-main {
		padding-left: 45px;
		padding-bottom: 75px;
	}
	.image-sub {
		position: absolute;
		border: solid 15px #fff;
		bottom: 0;
		left: 0;
		max-width: 75%;
		z-index: 1;
	}
}




.room-features {
	.textcontent {
		font-size: 0.85rem;
		opacity: 0.8;
	}
	&__feature {
		display: flex;
		margin-bottom: 25px;
	}
	img {
		width: 30px;
		margin-right: 10px;
	}
	p {
		margin-bottom: 0;
	}
}

.room-category {
	&__roomsize {
		font-size: 0.9rem;
		color: $color-primary;
	}
	&__price {
		text-align: right;
		position: relative;
		font-size: 0.8rem;
		span {
			font-size: 1.2rem;
		}
		&:before {
			content: "";
			height: 2px;
			width: 80%;
			position: absolute;
			display: flex;
			top: 10px;
			background: $color-primary;
		}
	}
	.divider {
		margin: 35px 0;
	}

}

.image-with-badge {
	position: relative;
	&__badge {
		position: absolute;
		top: -20px;
		right: -40px;
		z-index: 2;
		width: 210px;
	}
}




//Quest-Slider
.itssq_nav {
	margin-bottom: 40px;
}

//Kulinarik Grid
.kulinarik-grid {
	display: flex;
	flex-direction: column;


	@include responsive (landscape) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}
	@include responsive (desktop) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}
	&__1 {
		grid-row: 1 / span 2;
		@include responsive (landscape) {
			grid-column: 1;
			grid-row: 1 / span 2;
		}
	}
	&__2 {
		display: grid;

		@include responsive (landscape) {
			grid-column: 2;
			grid-row: 1;
		}
	}
	&__3 {
		display: none;
		@include responsive (landscape) {
			display: grid;
			grid-column: 2;
			grid-row: 2 / span 2;
		}
	}
	&__4 {
		display: none;
		@include responsive (landscape) {
			display: grid;
			grid-column: 3 / span 2;
			grid-row: 1 / span 2;
		}
	}
	&__5 {
		display: grid;

		@include responsive (landscape) {
			grid-column: 3;
			grid-row: 3 / span 1;
		}
	}
	&__6 {
		display: none;
		@include responsive (landscape) {
			display: grid;
			grid-column: 4;
			grid-row: 3;
		}
	}
	figure {
		height: 100%;
	}
	img {
		height: 100%;
		object-fit: cover;
	}
}


//======Highlight Box
.highlight-box {
	display: grid;
	background: $color-primary;
	align-items: center;
	justify-items: center;
	color: #fff;
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 25px;
	margin: 25px 0;
	@include responsive(landscape) {
		margin: 0;
	}
}

//======Highlight End


//======List Check
.list-check {
	li {
		list-style-image:url('/files/images/icons/check_icon.svg');
		&::marker {
			margin-right: 15px;
		}
	}
}
//====== End


//======Reveal Quest Slider
.reveal-quest {
	margin-bottom: 0px;
	padding: 20px 0;
	text-transform: uppercase;
	color: $color-primary;
	border-bottom: solid 1px $color-primary;
	cursor: pointer;
}

.reveal-quest-element {
	opacity: 0;
	height: 0px;
	&.active {
		height: auto;
		opacity: 1;
	}
}
//====== End




html body .vsc {
	max-width: 100% !important;
}

.vri-page {
	max-width: 100% !important;
}


//####Bubble Slider

.its-variants {
	background: $grey;
	padding: 25px 20px;
	margin-top: 60px;
}
