.main-navigation-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	.container {
      position: relative;
      display: flex;
      justify-content: space-between;
	}
  .logo, .logo svg {
    max-width: 180px;
    @include responsive(phone) {
      max-width: 250px;
    }
  }
  &.submenu--open {
    content: 'test';
    background: $color-primary;
    .level_1 span {
      color: #fff !important
    }
  }
}

//Main Navigation Level 1
.header__main-navigation {
  @include responsive(desktop) {
    display: flex;
  }
  overflow: visible;
  display: none;
  font-size: 1rem;
  position: unset;
  ul.level_1 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
     li {
       transition: all .3s;
       &:hover > ul.level_2 {
         display: block;
       }
       a.sibling {
         position: relative;
         &:hover:after {
           content: "";
           width: 100%;
           height: 2px;
           position: absolute;
           z-index: 1;
           bottom: 0;
           right: 0;
           transition: width 0.2s;
           background-color: $color-primary;
           left: 0;
         }
         &:after {
           content: "";
           width: 0%;
           height: 2px;
           position: absolute;
           z-index: 1;
           bottom: 0;
           right: 0;
           transition: width 0.2s;
           background-color: $color-primary;
         }
       }
     }
    a {
      text-decoration: none;
      transition-duration: .2s;
    }
    span {
      color: #fff;
      position: relative;
      padding: 44px 20px;
      display: block;
    }
  }
  .level_2 {
    span {
      padding: 10px;
    }
  }
}

.main-navigation__left {
  display: none;
  font-size: 0.85rem;
  @include responsive(desktop) {
    position: fixed;
    right: 0;
    padding-right: calc(50vw - 760px);
    top: 0;
    display: flex;
  }
  &__links {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    transform: translateY(-50%);
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 70px;
    & > *:not(:last-child) {
      padding: 15px;
      text-transform: uppercase;
    }
    li {list-style: none}
    a {color: #fff;}
  }
}

.header__main-navigation ul li.last span {
  padding-right: 0px;
}


.header__main-navigation ul.level_2 span {
  transition: transform .3s;
}

.header__main-navigation ul.level_2 li:hover span:not(.active) {
  color: $color-primary;
  transform: translateX(10px);
}

.header__main-navigation ul.level_2 span.active{
  color: $color-primary;
}



//====================Burger Icon

.mobile-navigation {
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  svg {
    fill: #fff;
    width: 40px
  }
}

.mm-menu {
  background: $color-primary;
  color: #fff;
}
//====================End
.mm-title {
  color: #fff !important;
}

.mm-next:after, .mm-prev:before {
  border-color: #fff !important;
}



.mm_dropdown {
  transition: opacity .3s;
  opacity: 0;
  position: absolute;
  width: max-content;
  bottom: 0;
  left: 50%!important;
  right: 0;
  z-index: -1;
  // display: flex;
  display: none;
  .mm_dropdown__subnavigation {
    height: 100%;
  }
  .inner {
    position: absolute;
    top: 20%;
    right: 15%;
    z-index: 9;
  }
  ul{
    @for $i from 2 through 12 {
      $j: 1.2rem;
      :nth-child(#{$i}) {transform: translateX(-($j*$i))}
    }
  }

  a:not(.btn) {
    color: #fff;
    font-weight: $light;
    &:hover {
      color: darken(#fff, 30);
    }
  }
  p {
    text-transform: initial;
  }
  li {
    margin-bottom: 15px;
    justify-content: flex-end;
    max-width: fit-content;
    width: auto;
    max-width: 130px;
    width: 100%;
    @include responsive(desktop) {
      max-width: fit-content;
      width: auto;
    }
  }
}


.submenu {
  &:hover {
    .mm_dropdown {
      display: flex;
      opacity: 1;
      left: auto;
    }
    .main-navigation-wrapper {
      background-color: $color-primary;
    }
  }
}


.top-bar {
  @include responsive(landscape) {
    display:flex;
    justify-content: flex-end;
  }
  display: none;
  font-size: 0.8rem;
  ul {
    display: flex;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.changelanguage {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    max-width: 200px;
  }
  span {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
  &__title {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-right: 10px;
    color: #fff;
  }
  span.active {
    border-bottom: solid 1px #fff;
  }
}

//Leaf Navigation
#leaf-menu {
  margin-right: 20px;
}


.leaf-navigation {
  opacity: 0;
  visibility: hidden;
  &__container {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 5;
  }
  &__leaf {
    position: absolute;
    bottom: 0px;
    display: flex;
    z-index: 5;
    &--rotated {
      position: absolute;
      left: 180px;
      bottom: -180px;
      width: auto;
      z-index: 1;
      opacity: 0;
    }
    svg {
      opacity: 0.8;
      width: 100%;
      height: 65vh;
      @include responsive(desktop) {
        height: 75vh;
      }
    }
  }
  &__nav {
    position: absolute;
    z-index: 100;
    top: 80%;
    transform: translatey(-65%);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    @include responsive(extralarge) {
      font-size: 1.4rem;
    }
    a {
      color: #fff;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    li {
      display: flex;
      & > span.active {
        color: #fff;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 2px;
      }
    }
  }
}

//Back to Top Link
.toplink {
  position: fixed;
  right: 40px;
  bottom: 20px;
  z-index: 99;
}


//####
