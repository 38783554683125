//FAQ Akkordeon
.ce_accordionSingle, .ce_accordion {
  margin-bottom: 40px;
  .toggler {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border-bottom: solid 1px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    .circle.plus {
      .vertical {
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
      }
      .horizontal {
        transition: all 0.5s ease-in-out;
        transform: rotate(-90deg);
        opacity: 1;
      }
    }
    &.active {
      opacity: 1;
      .vertical {
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
      }
      .horizontal {
        transition: all 0.5s ease-in-out;
        transform: rotate(90deg);
        opacity: 0;
      }
    }
  }

  .circle-plus {
    margin-right: 25px;
  }

  .circle-plus .circle {
    position: relative;
    width: 2.5em;
    height: 2.5em;
  }
  .circle-plus .circle .horizontal {
    position: absolute;
    background-color: $color-primary;
    width: 30px;
    height: 1px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
  }
  .circle-plus .circle .vertical {
    position: absolute;
    background-color: $color-primary;
    width: 1px;
    height: 30px;
    left: 50%;
    top: 50%;
    margin-top: -15px;
  }
}


//FAQ Akkordeon END