.job__teaser {
  position: relative;
  background: #fff;
  padding: 30px 20px;
  margin: 35px 0px 15px 0px;
  .second-column {
    display: flex;
    margin-top: 20px;
    font-size: 0.9rem;
    div:not(:last-child) {
      margin-right: 15px;
    }
  }
  h3 {
    font-weight: 400;
    margin: unset;
    font-size: 1.2rem;
    @include responsive(phone) {
      font-size: 1.5rem;
    }
  }
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    @include responsive(phone) {
      font-size: 1.5rem;
    }
  }
}