/*========================Variables======================*/
$font-headline: 'Sifonn Pro', sans-serif;
$font-stack-h1: 'Bebas Neue', sans-serif;
$font-stack-body: 'Montserrat', sans-serif;

$font-size-base: 1rem;
$font-size-imp: 1.25rem;
$font-size-small: 0.9rem;

$container-width: 1560px;


$color-primary: #B4C05A;
$color-secondary: #929A52;
$color-tertiary: #F9F7F1;
$color-header: #fff;
$color-dark: #707070;

$bold: 700;
$normal: 500;
$light: 400;
$grey: rgba($color-primary, 0.2);

$gap-default: 120px;
$gap-default-mobile: calc($gap-default / 2);

$shadowed: 1px 2px 14px 2px rgba(0,0,0,.22);



@mixin button-primary ($color, $background)  {
  color: $color;
  background-color: $background;
  padding: 0.65rem 1.2rem;
  width: auto;
  font-size: 0.9rem;
  font-weight: $normal;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
  @include responsive(phone) {
    //padding: 1rem 1.2rem;
  }
  &:hover {
    color: $color;
  }
}

@mixin image-caption {
  color: $color-dark;
  font-weight: $bold;
  font-size: 0.9rem;
}

@mixin icon ($size) {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: $size + px;
  width: $size + px;
}


//Media Structure 2019
@mixin responsive ($breakpoint) {
    @if ($breakpoint == phone) {
      @media screen and  (min-width: 576px) { @content };
    } 
    @if ($breakpoint == tablet) {
      @media screen and  (min-width: 768px) { @content };
    } 
    @if ($breakpoint == landscape ) {
      @media screen and  (min-width: 992px) { @content };
    } 
    @if ($breakpoint == desktop) {
      @media screen and  (min-width: 1200px) { @content };
    }
    @if ($breakpoint == extralarge) {
      @media screen and  (min-width: 1400px) { @content };
    }
  }